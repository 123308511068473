import React from "react";
import './LoginForm.css';
import { FaUser,FaLock } from "react-icons/fa";

const LoginForm = () => {
    return(
        <div className='wrapper'>
            <form action="">
                <h1>Accesso CED</h1>
                <div className="input-box">
                    <input type="text" placeholder="utente"  required />
                    <FaUser className='icon'/>
                </div> 
                <div className="input-box">
                    <input type="password" placeholder="password"  required />
                    <FaLock className='icon'/>
                </div> 

                <div className="remember-forgot">
                    <label><input type="checkbox" />Recupera password</label>
                    <a href="#">Passowrd dimenticata?</a>
                </div>

                <button type="submit">Accedi</button>
            </form>    
        
        </div>
    )
}

export default LoginForm